import Highcharts from "highcharts/highcharts";
import { parseJSON } from "./risk_by_domain";

document.addEventListener("turbolinks:load", () => {
  const charts: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-chart='timeline']"
  );

  if (charts) {
    Array.from(charts).forEach((chart) => {
      const plotBandColor = "#F7F7F7";
      const lineColor = "#909090";

      const series = parseJSON(chart.dataset.series);
      const categoryLabels = parseJSON(chart.dataset.dates);

      Highcharts.chart({
        chart: {
          renderTo: chart.id,
        },
        colors: [
          "#EE7660",
          "#5BAA8C",
          "#FFCD6D",
          "#A7C4EE",
          "#617CC9",
          "#FFB79F",
          "#ABE8D1",
          "#A5A03B",
        ],

        title: {
          text: "Risk Score Over Time",
          style: { color: "#A9A9A9" },
          align: "left",
        },

        xAxis: {
          categories: categoryLabels,
          gridLineWidth: 2.3,
          lineColor: lineColor,
          lineWidth: 2,
          minPadding: 0,
          maxPadding: 0,
        },

        yAxis: {
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 2,
          lineColor: lineColor,
          min: 0,
          max: 100,
          tickInterval: 10,
          plotBands: [
            {
              color: plotBandColor,
              from: 10,
              to: 20,
            },
            {
              color: plotBandColor,
              from: 30,
              to: 40,
            },
            {
              color: plotBandColor,
              from: 50,
              to: 60,
            },
            {
              color: plotBandColor,
              from: 70,
              to: 80,
            },
            {
              color: plotBandColor,
              from: 90,
              to: 100,
            },
          ],
        },
        legend: {
          layout: "vertical",
          itemMarginTop: 26,
          itemMarginBottom: -10,
          align: "right",
          verticalAlign: "top",
        },

        series: series,

        credits: {
          enabled: false,
        },

        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: true,
                lineWidth: 5,
              },
            },
            marker: {
              enabled: false,
            },
            lineWidth: 2.5,
          },
        },

        tooltip: {
          formatter: function () {
            return (
              '<span style="color:' +
              this.color +
              '">\u25CF</span>' +
              " " +
              this.series.name +
              ": " +
              "<b>" +
              this.y +
              "</b>"
            );
          },
        },

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      });
    });
  }
});
