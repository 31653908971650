import Highcharts from "highcharts";
import {
  chartBackgroundColor,
  xAxisLabelStyle,
  lineColor,
  xAxisTickWidth,
  crpBarChartYAxisOptions,
  columnTooltipFormatter,
  plotOptionsBarBorderColor,
  plotOptionsBarBorderWidth,
  plotOptionsBarGrouping,
  plotOptionsBarShowInLegend,
  plotOptionsBarThreshold,
  barChartBorderRadius,
} from "./globals";
import { riskZonesGreenToRed } from "./risk_colors";

function controlFamilyChartHeight(count) {
  if (count > 4) {
    return 38 * count;
  } else {
    return 180;
  }
}

function isPresent(element) {
  return element != [] && element != undefined && element != false;
}

document.addEventListener("turbolinks:load", () => {
  const charts: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-chart='control_family_risk_profile']"
  );

  if (charts) {
    Array.from(charts).forEach((chart) => {
      const controlFamilyCrpValues = JSON.parse(chart.dataset.crpValues);
      const comparativeCrpData = JSON.parse(chart.dataset.comparativeCrpData);
      const controlFamilyPercentiles = JSON.parse(
        chart.dataset.controlFamilyPercentiles
      );
      const scores = Object.keys(controlFamilyCrpValues).map((key) => {
        return controlFamilyCrpValues[key];
      });
      const controlFamilyNames = JSON.parse(chart.dataset.resourceNames);

      Highcharts.chart(chart.id, {
        chart: {
          backgroundColor: chartBackgroundColor,
          type: "bar",
          height: controlFamilyChartHeight(
            Object.keys(controlFamilyCrpValues).length
          ),
        },
        title: {
          text: "",
        },
        xAxis: [
          {
            labels: {
              style: xAxisLabelStyle,
            },
            lineColor: lineColor,
            tickColor: lineColor,
            tickWidth: xAxisTickWidth,
            categories: Object.keys(controlFamilyCrpValues),
          },
          {
            visible: isPresent(controlFamilyPercentiles),
            opposite: true,
            categories: isPresent(controlFamilyPercentiles)
              ? controlFamilyPercentiles
              : [],
            labels: {
              style: {
                fontSize: "12px",
                fontWeight: "bold",
              },
            },
            lineWidth: 0,
            tickWidth: 0,
            title: {
              text: "Percentile Score",
              style: {
                color: "gray",
                fontSize: "14px",
                fontWeight: "bold",
              },
            },
          },
        ],
        yAxis: crpBarChartYAxisOptions,
        tooltip: {
          enabled: true,
          formatter: function () {
            return columnTooltipFormatter(
              this,
              controlFamilyNames,
              comparativeCrpData,
              scores
            );
          },
        },
        plotOptions: {
          bar: {
            animation: false,
            borderColor: plotOptionsBarBorderColor,
            borderWidth: plotOptionsBarBorderWidth,
            grouping: plotOptionsBarGrouping,
            showInLegend: plotOptionsBarShowInLegend,
            threshold: plotOptionsBarThreshold,
            zones: riskZonesGreenToRed,
          },
          series: {
            borderRadiusTopLeft: barChartBorderRadius,
            borderRadiusTopRight: barChartBorderRadius,
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            name: "container",
            showInLegend: false,
            animation: false,
            data: Object.keys(controlFamilyCrpValues).map((_) => 100.01),
            tooltip: {
              followPointer: true,
            },
          },
          {
            xAxis: 1,
            name: "score",
            showInLegend: false,
            data: scores,
          },
          {
            name: "",
            visible: comparativeCrpData,
            data: comparativeCrpData,
            pointStart: 0,
            pointInterval: 1,
            lineWidth: 0,
            type: "line",
            color: "black",
            showInLegend: false,
            states: {
              hover: {
                enabled: false,
              },
            },
            marker: {
              fillColor: "lightgray",
              lineWidth: 1,
              lineColor: "black",
            },
          },
        ],
      });
    });
  }
});
