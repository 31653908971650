const riskColors = [
  "#bc0000",
  "#ca1a00",
  "#df4100",
  "#f26700",
  "#ff8000",
  "#fe9800",
  "#fcbb00",
  "#fad400",
  "#a4bf10",
  "#3ea126",
];

export function setRiskColorRange(colors: string[]) {
  return {
    10: colors[0],
    20: colors[1],
    30: colors[2],
    40: colors[3],
    50: colors[4],
    60: colors[5],
    70: colors[6],
    80: colors[7],
    90: colors[8],
    100.01: colors[9],
  };
}

export const riskColorRangeRedToGreen = setRiskColorRange(riskColors);
export const riskColorRangeGreenToRed = setRiskColorRange(riskColors.reverse());

export function setRiskZones(colorRange) {
  const zones = [];
  for (const key in colorRange) {
    zones.push({ value: key, color: colorRange[key] });
  }
  zones.push({ color: "rgba(255, 255, 255, 0)" });
  return zones;
}

export const riskZonesRedToGreen = setRiskZones(riskColorRangeRedToGreen);
export const riskZonesGreenToRed = setRiskZones(riskColorRangeGreenToRed);
