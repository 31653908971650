import { riskColorRangeRedToGreen, riskZonesGreenToRed } from "./risk_colors";
import * as Highcharts from "highcharts";

const riskThresholds = {
  low: 0,
  medium: 20,
  high: 50,
};

const riskLabels = {
  10: "Low Risk",
  35: "Medium Risk",
  75: "High Risk",
};

export const lineColor = "#999492";
export const riskZoneLineColor = "#dadada";
export const primaryGraphColor = "#424343";
export const crosshairColor = "rgba(209, 236, 255, .66)";

function riskLabeler(crp_value) {
  const riskValues = {
    low: {
      value: riskThresholds.low,
      text: "Low Risk",
    },
    medium: {
      value: riskThresholds.medium,
      text: "Medium Risk",
    },
    high: {
      value: riskThresholds.high,
      text: "High Risk",
    },
  };
  if (crp_value >= riskValues.high.value) {
    return riskValues.high.text;
  } else if (crp_value >= riskValues.medium.value) {
    return riskValues.medium.text;
  } else {
    return riskValues.low.text;
  }
}

export function portfolioColumnTooltipFormatter(column) {
  const i = column.series.data.indexOf(column.point);
  const risk_column = column.series.chart.series[1].points[i];
  return (
    "<span>" +
    column.key +
    "</span><br/>" +
    '<span style="color:' +
    risk_column.color +
    '">\u25CF</span><b> ' +
    riskLabeler(risk_column.y) +
    "</b><br/>"
  );
}

export function columnTooltipFormatter(
  column: Highcharts.TooltipFormatterContextObject,
  resourceNames: string[],
  comparativeCrpData: number[],
  scores: number[]
) {
  const i = column.series.data.indexOf(column.point);
  const risk_column = column.series.chart.series[1].points[i];
  const bullet = "<span>\u25CF</span><b>";
  const score =
    bullet +
    "<span>" +
    " Score: " +
    (100 - Math.round(scores[i])) +
    "</span><br/>";
  const averageScore = comparativeCrpData
    ? "<span>\u25CF</span><b>" +
      "<span>" +
      " Average Score: " +
      (100 - Math.round(comparativeCrpData[i])) +
      "</span><br/>"
    : "";
  return (
    "<span>" +
    resourceNames[i] +
    "</span><br/>" +
    '<span style="color:' +
    risk_column.color +
    '">\u25CF</span><b> ' +
    riskLabeler(risk_column.y) +
    "</b><br/>" +
    score +
    averageScore
  );
}

export function controlCategoryDataLabelFormatter(series, average_score) {
  const average_score_text =
    average_score == false
      ? ""
      : "<span style='color: #999;font-size: .8em;font-weight: bold;'>AVG: " +
        average_score +
        "%</span>";
  return `
    <div class='text-gray-700 text-center whitespace-normal'>
      <div class='font-semibold text-xl'>
        ${series.y}%
      </div>
      <div class='text-lg'>controls complete</div>
      <div>${average_score_text}</div>
    </div>
  `;
}

const riskGaugeOptions = [];
for (const key in riskColorRangeRedToGreen) {
  riskGaugeOptions.push({
    y: 10,
    name: "",
    color: riskColorRangeRedToGreen[key],
  });
}

export function positionDial(crp_value) {
  return (crp_value / 100) * 90;
}

function setLabelFontSize() {
  return "10.5px";
}

export const chartFont = "helvetica";
export const chartBackgroundColor = null;
export const xAxisTickWidth = 0;
export const labelFontSize = setLabelFontSize();
export const labelTextOverflow = "none";

export const crpBarChartYAxisOptions = {
  plotBands: [
    {
      color: riskZoneLineColor,
      from: 19.8,
      to: 20.1,
    },
    {
      color: riskZoneLineColor,
      from: 49.8,
      to: 50.1,
    },
  ],
  lineWidth: 1,
  lineColor: lineColor,
  gridLineWidth: 0,
  gridLineColor: lineColor,
  opposite: true,
  tickPositions: [-2, 0, 10, 35, 75, 100],
  title: {
    text: "",
  },
  labels: {
    style: {
      color: primaryGraphColor,
      fontSize: labelFontSize,
    },
    formatter: function () {
      const value = riskLabels[this.value];
      return value !== "undefined" ? value : this.value;
    },
  },
};

export const xAxisLabelStyle = {
  fontSize: labelFontSize,
  color: primaryGraphColor,
  textOverflow: labelTextOverflow,
};

export const plotOptionsBarBorderColor = null;
export const plotOptionsBarBorderWidth = 0;
export const plotOptionsBarGrouping = false;
export const plotOptionsBarShowInLegend = false;
export const plotOptionsBarThreshold = -2;
export const plotOptionsBarZones = riskZonesGreenToRed;
export const barChartBorderRadius = 3;
