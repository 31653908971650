import Highcharts from "highcharts";
import {
  barChartBorderRadius,
  chartBackgroundColor,
  crosshairColor,
  crpBarChartYAxisOptions,
  lineColor,
  plotOptionsBarBorderColor,
  plotOptionsBarBorderWidth,
  plotOptionsBarGrouping,
  plotOptionsBarShowInLegend,
  plotOptionsBarThreshold,
  portfolioColumnTooltipFormatter,
  xAxisLabelStyle,
  xAxisTickWidth,
} from "./globals";
import { riskZonesGreenToRed } from "./risk_colors";

type CrpData = {
  categories: string[];
  container: number[];
  values: number[];
};

function riskByRankChartHeight(count: number) {
  if (count > 10) {
    return 25 * count;
  } else {
    return 250;
  }
}

document.addEventListener("turbolinks:load", () => {
  const charts: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-chart='risk_by_rank']"
  );

  if (charts) {
    Array.from(charts).forEach((chart) => {
      const crpData: CrpData = JSON.parse(chart.dataset.riskByRank);

      Highcharts.chart({
        chart: {
          renderTo: chart.id,
          backgroundColor: chartBackgroundColor,
          type: "bar",
          height: riskByRankChartHeight(crpData.categories.length),
        },
        title: {
          text: "",
        },
        xAxis: {
          labels: {
            style: xAxisLabelStyle,
          },
          categories: crpData.categories,
          crosshair: {
            color: crosshairColor,
          },
          lineColor: lineColor,
          tickColor: lineColor,
          tickWidth: xAxisTickWidth,
        },
        yAxis: crpBarChartYAxisOptions,
        tooltip: {
          enabled: true,
          formatter: function () {
            return portfolioColumnTooltipFormatter(this);
          },
        },
        plotOptions: {
          bar: {
            borderColor: plotOptionsBarBorderColor,
            borderWidth: plotOptionsBarBorderWidth,
            grouping: plotOptionsBarGrouping,
            showInLegend: plotOptionsBarShowInLegend,
            threshold: plotOptionsBarThreshold,
            zones: riskZonesGreenToRed,
          },
          series: {
            borderRadiusTopLeft: barChartBorderRadius,
            borderRadiusTopRight: barChartBorderRadius,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  location.href = this.options.url;
                },
              },
            },
          },
        },
        series: [
          {
            type: "bar",
            name: "container",
            showInLegend: false,
            animation: false,
            data: crpData.container,
            tooltip: {
              followPointer: true,
            },
          },
          {
            type: "bar",
            name: "score",
            showInLegend: false,
            data: crpData.values,
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: true,
              },
            },
          },
        ],
      });
    });
  }
});
